.sideBarCont {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    width: 40px;
    border: var( --border-light );
    z-index: 10;
    padding-top: 8px;
    background-color: var( --color-background-mild );
}

.sideBarIconCont {
    position: relative;
    margin-bottom: 2px;
}

.currentModeIndicator {
    z-index: 0;
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    background-color: rgba(0,0,0,0.1);
    pointer-events: none;
}