.surveyEditorMainView {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
}

.loaderContainer {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.splitContainer {
    position: absolute;
    top: 0; right:40px; bottom:0; left:40px;
    width: calc( 100% - 40px );
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}

.propertiesArea {
    position: relative;
    overflow: hidden;
    transition: width 0.3s ease-out;
}

.contentArea {
    position: relative;
    background-color: var( --color-background-light );
    overflow: hidden;
    transition: width 0.3s ease-out;
}

.previewArea {
    position: relative;
    background-color: var( --color-background-light );
    transition: width 0.3s ease-out;

}

.lockContainer {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    background-color: rgba( 255,255,255,0.4);
    z-index: 99;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.lockPanel {
    background-color: var( --color-background-extradark );
    padding: 8px 18px 12px 18px;
    border-radius: var( --corner-radius-default );
    color: white;
    align-self: flex-start;
    margin-top: var( --space-small );
    font: var( --font-body );
    display: flex;
    justify-content: center;
    align-items: center;
}