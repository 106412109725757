.poperPanel {
    position: relative;
    background-color: var(--color-background-light);
    padding-top: calc( var(--space-small) - 4px);
    padding-bottom: calc( var(--space-small) - 4px);
    border-radius: var(--corner-radius-default);

    box-shadow: var(--box-shadow-low);
    max-height: 80vh;
    max-width: 100vw;
    box-sizing: border-box;
    z-index: 999;
    pointer-events: all;
}