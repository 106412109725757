.panel {
    position: relative;
    min-width: 600px;
    /* min-height: 400px; */
    /* padding-bottom: 64px; */
    /* width: 60vw; */
    /* height: 80vw; */
}

.footer {
    position: sticky;
    margin-top: auto;
    bottom: 0;
    padding-top: var(--space-small);
    display: flex;
    justify-content: space-between;
    background-color: var(--color-background-light);
}

/* LinkItem */
.linkMainCont {
    border-bottom: var(--border-light);
    padding-bottom: var(--space-small);
    margin-bottom: var(--space-small);
}

.rowCont {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.textInput {
    margin-inline-end: 8px;
}

.textInput:last-child {
    margin-inline-end: 0;
}
