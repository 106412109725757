.mainContainer {
    display: flex;
    font-size: 18;
    background-color: rgba(0,0,0,0.06);
    border-radius: 50px;
    align-items: center;
    padding: 6px 12px;
    user-select: none;
}

.mainContainer:hover {
    background-color: rgba(0,0,0,0.2);
}

.text {
    /* margin: 0px 8px 0px 8px; */
}