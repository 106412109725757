.login_root {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: var(--color-background-mild);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 50px;
    margin-bottom: 24px;
}

.formPanel {
    margin-top: 24px;
    width: 300px;
    padding: var(--space-medium);
    background-color: var(--color-background-light);
    border-radius: var(--corner-radius-large);
    box-shadow: var(--box-shadow-low);
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.error {
    color: var(--color-error-medium);
    margin-top: 8px;
    line-height: 24px;
}
