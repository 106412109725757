.logicPhaseCont {
    margin-bottom: var( --space-small );
    border-bottom: var( --border-light );
    padding-bottom: var( --space-small );
}

.phaseTitleCont {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    user-select: none;
    cursor: pointer;
}

.inoutIcon {
    width: 32px;
    height: 24px;
    border-radius: 10px;
    background-color: var( --color-surface );
    margin: 0 12px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.itemsCont {
    /* padding-inline-start: 8px; */
    padding-top: var( --space-small );
    margin-block-start: 0;
    margin-block-end: 0;
}

.createItemCont {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    border-inline-start: 4px solid rgba(0,0,0,0.1);
    margin-inline-end: 32px;
}

.createItemCont:hover {
    background-color: rgba(0,0,0,0.05);
}

.itemCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.itemTitleInput{
    font: var( --font-body-medium );
    width: 100%;
}

.itemBg {
    /* border-radius: var( --corner-radius-default ); */
    /* border: var( --border-light ); */
    flex: 1;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border-inline-start: 4px solid var( --color-type-low-emphasis );
    background-color: rgba( 255,255,255,0.4);
    margin-inline-end: 8px;
    cursor: pointer;

    transition-property: background-color;
    transition-duration: 0.3s;
}

.itemBg:hover {
    box-shadow: var( --box-shadow-low );
}

.dragHandle {
    --border: 1px solid var( --color-type-medium-emphasis );

    width: 16px; height: 8px;
    border-top: var( --border );
    border-bottom: var( --border ); 
    margin-inline-end: 12px;
}

.navigationItemsTitle {
    font: var(--font-body-small );
    color: var(--color-type-low-emphasis );
    margin: var( --space-small ) 0 8px;
}

.navigationItem {
    font: var( --font-code );
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
}