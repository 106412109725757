.rowContStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: var( --space-medium ) 0 var( --space-small );
}

.tagContainer {
    margin-inline-end: 6px;
    margin-bottom: 8px;
}

.inputCont {
    height: 26px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 8px;
    /* background-color: var( --color-primary-extra-light ); */
    display: flex;
    align-items: center;
}

.inputMessage {
    margin-inline-start: 8px;
    color: var( --color-type-low-emphasis );
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;   
}

.listContainer {
    max-height: 40vh;
    overflow-y: auto;  
}

.nameSpan {
    font: var( --font-body-medium );
    padding: 5px;
}

.listItem {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.listItemHighlight {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    background-color: var(--color-primary-light);
}

