.barContainer {
    width: 100%;
    height: 44px;
    background-color: var(--color-primary-medium );
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    font: var(--font-body );
    color: var( --color-onPrimary );
}