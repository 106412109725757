@value gap: 10px;

.listContainer {
    position: relative;
    max-height: 100%;

    max-height: calc( 80vh - 60px );
    /* margin-top: 48px; */
    box-sizing: border-box;
    overflow-y: auto;
}

.topCont {
    position: relative;
    /* top: 0; left: 0; right: 0; */
    padding: 0 8px 8px 8px;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var( --color-background-light );
    border-radius: var( --corner-radius-default );
}


.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.listItem {
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: gap;
    padding-bottom: gap;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listItem:hover {
    background-color: var(--color-primary-light);
}

.listGap{
    width: gap;
}

