#pageNotFoundContainer {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-background-mild);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

