.legend {
    width: 100%;
    font: var( --font-body );
    margin-bottom: 12px;
}

.scaleValuesCont {
    margin-top: var( --space-medium );
    /* padding-bottom: var( --space-small ); */
    display: flex;
}

.secondaryButtonCont {
    margin-top: 4px;
    width: 100%;
    max-width: 180px;
    display: flex;
}

.gap {
    min-width: 8px;
}

.scaleValueInputCont {
    padding: 0 2px; 
    min-width: 32px; 
    flex-grow: 1;
    flex-shrink: 0
}

.itemKeyCont {
    display: flex;
    align-items: center;
}

.itemKey {
    width: 100%;
    height: 28px;
    padding: 6px 0;
    direction: ltr;
    background-color: transparent;
    font: var( --font-code );
    font-size: 12px;
    color: var( --color-type-low-emphasis);

    text-align: center;

    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}

