.container {
    display: flex;
    align-items: center;
}

.box {
    background-color: var(--color-background-medium);
    border: var(--border-main);
    cursor: pointer;

    transition: background-color 0.2s ease-out
}

.box:hover {
    border: 1px solid var(--color-background-xxdark)
}


.knob {
    background-color: var(--color-background-light);
}

.label {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    margin: 0 8px;
}