.cardCont {
    position: relative;
    height: 150px;
    box-sizing: border-box;
    hyphens: auto;
    background-color: var( --color-background-light );
    border-radius: var( --corner-radius-large );
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
}

.cardCont:focus {
    background-color: var( --color-primary-extra-light );
}

.cardCont:hover {
    box-shadow: var( --box-shadow-low );
}

.cardCont:focus .labelsLine {
    transform: scaleX( 1.6 )
}

.cardCont:hover .labelsLine {
    transform: scaleX( 1.6 )
}

.topSection {
    flex-grow: 1;
    padding: 16px;
    padding-top: 12px;
    padding-inline-end: 8px;
    height: 80px;
    position: relative;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 16px; */
    padding-bottom: 8px;
    padding-inline-start: 22px;
    padding-inline-end: 8px;
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
}

.cardTitle {
    font: var( --font-body-medium );
    color: var( --color-type-medium-emphasis );
    margin-bottom: -7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0px;
    padding-inline-start: 6px;
    display: flex;
    justify-content: space-between;
}

.optionButton {
    align-self: flex-end;
}

.participants {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    height: 40px;
    width: 40px;
    align-self: flex-end;
}

.whiteCover {
    height:100px;
    width: 30px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 69%, rgba(255,255,255,1) 100%);
    position: absolute;
    right: 0;
    top: 0;
}

.detailTitle {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    display: 'inline';
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.labelMarkCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}



.labelMarkRow {
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    opacity: 0.8;
}

.activityBlock {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var( --color-background-mild );
    opacity: 0.7;
}

.labelsLine {
    position: absolute;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 5px;
}

.labelPart {
    flex: 1    
}

.starCont {
    position: fixed;
    margin-block-end: 10px;
}