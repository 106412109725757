.toolbarCont {
    font: var(--font-body);
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.dynamicTextDecorator {
    position: relative;
    z-index: 0;
    color: var(--color-type-medium-emphasis);

    /* border: 2px dotted var(--color-type-low-emphasis);
    border-width: 2px 0; */

    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: var(--color-type-medium-emphasis);
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}

.entityEditorFooter {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

/* DynamicTextEntityEditor */

.dtEditorHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.dtModeTip {
    font: var(--font-body-small);
    color: var(--color-type-low-emphasis);
    margin-bottom: 16px;
}

.conditionalItemsListCont {
    margin-bottom: 16px;
    max-height: calc(48vh - 256px);
    min-height: 96px;
    padding: 0 8px;
    overflow-y: auto;
}

.conditionalItemCont {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: var(--color-background-light);
    margin-bottom: 8px;
    border-radius: var(--corner-radius-large);
    box-shadow: var(--box-shadow-low);
    overflow: auto;
}

.ifLabel {
    font: var(--font-body-small);
}

.defaultDataCont {
    display: flex;
    align-items: flex-end;
}

/* Text Color Picker */
.colorBtn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
}

.colorBtn:hover {
    border: 2px solid var(--color-type-medium-emphasis);
}

.pickerCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}
