.mainCont {
    background-color: rgba(255, 255, 255, 1);
    background-color: var( --color-background-light );
    width: 280px;
    height: 100vh;
    position: absolute;
    right: calc( 100% - 280px - 2*var( --space-medium ));
    left: calc( 100% - 280px - 2*var( --space-medium ));
    z-index: 100;
    filter: var( --shadow-low );
    padding: 8px var(--space-medium) var(--space-medium) var(--space-medium);  
    /* transition-duration: 0.4s; */
}

.xBtnCont {
    display: flex;
    flex-direction: row-reverse;
}

.firstSectionCont {
    margin-top: 12px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: var( --border-light );
}

.userDetailCont {
    display: flex;
    flex-direction: row;
}

#userDetailTextCont {
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: center;
    margin: 0px var( --space-medium ) 0px var( --space-medium );
}

#profileImage {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: var( --color-background-mild );
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#profileName {
    font: var( --font-headline-h2 );
    color: var( --color-type-high-emphasis );
}

#email {
    font: var( --font-body );
    color: var( --color-type-low-emphasis );
}

#preferencesLable {
    font: var( --font-body );
    color: var( --color-type-low-emphasis );
}

#languageLable {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin-top: 12px;
}