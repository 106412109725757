
.analysisCard {
    position: relative;
    flex-grow: 1;
    /* width: 500px; */
    height: fit-content;
    box-sizing: border-box;

    margin: 12px;
    background-color: var( --color-background-light );
    border-radius: var(--corner-radius-large);
    display: flex;
    overflow: hidden;

    /* For some unknown reason, filter causes a bug for positioning tooltips inside this container:*/
    /* filter: var(--shadow-extra-low); */

    box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.cardDetailsCont {
    position: relative;
    flex: 1;
    padding: var( --space-medium );
    overflow-wrap: break-word;
}


.cardDetailsCont::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


.activityCont {
    position: absolute;
    top: 0; right:0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba( 255,255,255,0.6);
}

.optionsBtnCont {
    position: absolute;
    bottom: var( --space-medium );
    left: var( --space-medium );
    right: var( --space-medium );
    width: 32px;
}

.cardDetailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-small);
}

.cardTitle {
    width: 100%;
    font: var( --font-headline-h2-bold );
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardFieldCont {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

.cardFieldLabel {
    flex: 1;
    min-width: 180px;
    max-width: 180px;
    display: inline-block;
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cardFieldValue {
    flex: 3;
    display: inline-block;
    min-width: 200px;
    font: var( --font-body );
    color: var( --color-type-heigh-emphasis );
}

.cardStatus {
    padding: 32px;
    padding-bottom: 0;
    font: var( --font-body );
    color: var( --color-type-low-emphasis );
    text-align: center;
}

.cardStatusError {
    padding: 32px;
    font: var( --font-body );
    color: var( --color-error-medium );
}

.isArchivedCont {
    position: absolute;
    top: 0; right:0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.isArchivedLabel {
    transform: rotate( 30deg );
    color: var( --color-error-medium );
    opacity: 0.5;
}

.cardOutputCont {
    position: relative;
    /* width: 300px; */
    width: 35%;
    padding: var( --space-medium );
    padding-top: var( --space-small );
    outline: var( --border-light );
    display: flex;
    flex-direction: column;
}

.cardOutputMenuCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.outputArrowsCont {
    display: flex;
    width: 52px;
    justify-content: space-between;
}

.chooseOutputDropdown {
    max-width: 180px;
    min-width: 180px;
}

.cardOutputFileCont {
    position: relative;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;

}

.cardImage {
    height: 100%;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    object-fit: contain;
}

.fullsizeImageCont {
    object-fit: contain;
    height: 80vh;
    width: 80vw;
}

.cardOutputText {
    margin: 0;
    font: var( --font-headline-h2 )
}

.positionInQueueCont {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: var( --color-background-dark );
    color: var( --color-onDark );
    font: var( --font-body );
    line-height: 1.4em;
}

.strong {
    font: var( --font-body-bold );
}

.minorActivityIndicatorCont {
    position: absolute;
    bottom: var( --space-medium );
    left: calc( 100% - var( --space-medium ) - 18px );
    right: calc( 100% - var( --space-medium ) - 18px );
}

/*** ANALYSIS DETAILS DIALOG ***/

.detailsDialogCont {
    
    direction: ltr;
    box-sizing: border-box;
    width: 800px;
}

.logCont {
    box-sizing: border-box;
    height: 60vh;
    max-height: 600px;
    margin-top: 8px;
    padding: var( --space-small );
    border-radius: var( --corner-radius-default );
    border: var( --border-light );
    font: var( --font-code );
    font-size: 0.8em;
    overflow-wrap: break-word;
    overflow: auto;
    background-color: #1e1e1e;
    /* color: blue; */
}

.logBlackCont {
    background-color: black;
    color: var( --color-secondary-light );
    box-sizing: border-box;
    height: 60vh;
    max-height: 600px;
    margin-top: 8px;
    padding: var( --space-small );
    border-radius: var( --corner-radius-default );
    border: var( --border-light );
    font: var( --font-code );
    font-size: 0.8em;
    overflow-wrap: break-word;
    overflow: auto;
}

.cardDetailsLine {
    width: 100%;
    box-sizing: border-box;
    border-bottom: var( --border-light );
    margin: var( --space-medium ) 0;
}

/*** CARD NOTE ***/

.noteMainCont {
    /* margin-top: var( --space-small ); */
    margin-bottom: var( --space-very-large );
}

.noteContent {
    margin-top: var( --space-large );
    padding: 8px 0;
    border-top: var( --border-light );
}

.noteContent p {
    font: var( --font-body );
}

.noteContent li {
    font: var( --font-body );
}
