.mainCont {
    padding: 12px 24px 12px 24px;
    width: 300px;
}

.title {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin-bottom: 18px;
    /* margin: var( --space-medium ) 0px var( --space-small ) 0px */
}

#labelItemCont {
    width: 240px;
    min-width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0px 8px 0px;
}

.labelTitle {
    margin: 0px 12px 0px 12px;
}

.detailText {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    margin-bottom: 8px;
}

.blockCont {
    margin-bottom: 32px;
}

#accessCont {
    display: flex;
    flex-wrap: wrap;
    max-height: 80px;
    overflow-y: scroll;
}

#emojiCont {
    width: 80px;
    height: 80px;
    background-color: var( --color-background-mild );
    border-radius: var( --corner-radius-large );
    display: flex;
    justify-content: center;
    align-items: center;
}

#emoji {
    font-size: 40px;
}