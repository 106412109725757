.mediaSettingsCont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

/* Survey Media */
.surveyMediaCont {
    flex: 1;
    background-color: var(--color-background-mild);
    padding: var(--space-large);
}

/* Page Media */
.pageMediaCont {
    flex: 10;
    padding: var(--space-large);
    padding-top: var(--space-small);
    border-bottom: var(--border-light);
}

.mediaSelectionCont {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.radioOptionsCont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: var(--space-medium);
}
