.languagesCont {
    margin: 24px 0;
}

.customColorsCont {
    margin-top: 6px;
    width: 100%;
    display: flex;
    justify-content: stretch;
}

/* SPLASH SETTINGS */
.splashHeaderCont {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.noSurveyName {
    margin-bottom: 8px;
    color: var(--color-type-low-emphasis);
}

.changeLogoBtnsCont {
    display: flex;
    align-items: center;
}

.logoFileInput {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.splashLogoCont {
    overflow: hidden;
    margin-top: 18px;
    padding: 12px 0;
    box-sizing: border-box;
}

.logoLabel {
    margin-bottom: 8px;
    color: var(--color-type-medium-emphasis);
}

.logoUrlLink {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
