.placeHolderCont {
    background-color: var(--color-background-mild);
    width: calc(100% - 2 * var(--space-large));
    padding: var(--space-large);
    height: calc(100vh - 280px);
    border-radius: var(--corner-radius-large);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-type-low-emphasis);
}

.tableContainer {
    position: relative;
    height: calc(100vh - 200px);
    width: 100%;
    overflow: auto;

    border-radius: var(--corner-radius-default);
    padding: 0;
    box-shadow: var(--box-shadow-medium);
}

.table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: white;
}

.th {
    /* position: relative; */
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
    min-width: 130px;
    position: -webkit-sticky;
    position: sticky;
    color: var(--color-type-high-emphasis);
    font: var(--font-body-small);
    font-family: "Ploni";
    top: 0;
    z-index: 2;
}

.headerCell {
    position: relative;
    background-color: var(--color-primary-light);
    padding: var(--space-small);
}

.virtualHeaderCell {
    /* position: relative;
    top: 0; left: 0; right: 0; bottom: 0;
    width: fit-content; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-secondary-light);
    padding: var(--space-small);
}

.virtualHeaderCell:hover {
    background-color: var(--color-secondary-medium);
}

.tr {
    padding: 0 16px;
}

.td {
    position: relative;
    color: var(--color-type-high-emphasis);
    font: var(--font-body-small);
    border-bottom: var(--border-light);
    vertical-align: middle;
    padding: 14px;
    white-space: nowrap;
}

.colTitle {
    font: var(--font-body-small);
    color: var(--color-type-medium-emphasis);
}

.colKey {
    font: var(--font-body-small-medium);
    /* color: var( --color-type-high-emphasis ); */
}

.rowCell {
    white-space: pre-wrap;
    word-break: break-word;
}

.virtualRowCellBG {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: var(--color-secondary-extra-light);
    padding: var(--space-small);
}

.colType {
    font: var(--font-body-extra-small);
    margin: 0 auto;
    margin-bottom: 5px;
    padding: 1px 2px 0px 2px;
    text-align: center;
    width: fit-content;
}

.optionsBtnCont {
    margin: 0 8px;
}

.cellActivity {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000020;
}
