#filterBtnCont {
    margin: 0 8px 0 8px
}

#filterCont {
    padding: 0 var(--space-small);
}

#applyCont {
    display: flex;
    justify-content: space-between;
    margin: 16px 0 4px 0;
}
