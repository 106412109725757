.mainContainer {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    overflow: hidden;
}

.contentContainer {
    position: relative;
    padding: var( --space-medium );
    padding-top: var( --space-large );
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: var( --color-background-mild );
    overflow: scroll;
}

.sidePanel {
    display: flex;
    flex-direction: column;
    width: 240px;
    min-width: 240px;

    background-color: var( --color-background-light );
}

.sectionContainer {
    width: 240px;
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sideBarSectionA {
    display: flex;
    flex-direction: column;
    padding: var( --space-medium );
    border-bottom: var( --border-light );
}

.sideBarSectionB {
    display: flex;
    flex-direction: column;
    padding: var( --space-medium );
}

/* .teamName {
    font: var( --font-headline-h2 );
    color: var( --color-type-medium-emphasis );
} */

.labelHeadline {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin: var( --space-medium ) 0px var( --space-small ) 0px
}

#labelsList {
    margin-bottom: 12px;
}