.argCont {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 500px;
    width: 50%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 4px;
}

.argInput {
    /* flex: 2; */
    box-sizing: border-box;

    margin-inline-end: 8px;
}