.mainCont {
    position: relative;
    width: calc( 100vw - 200px );
    height: calc( 100vh - 200px );
    min-width: 1000px;
    min-height: 600px;

    display: flex;
}

.editorTitle {
    font: var(--font-headline-h3-bold);

}

.activityIndicatorCont {
    position: absolute;
    top: 0; right:0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #F8F8Fdaa;

}

/************/
/* SETTINGS */
/************/

.varSettingsArea {
    position: relative;
    width: 25%;
    padding: var( --space-very-large );
    outline: var(--border-light);
    box-sizing: border-box;
}

.submitCont {
    position: absolute;
    bottom: var( --space-very-large );
    left: var( --space-very-large );
    right: var( --space-very-large );
    display: flex;
}

.settingsInputField {
    margin-bottom: 12px;
}

/***********/
/* FORMULA */
/***********/

.formulaArea {
    width: 50%;
    padding: var( --space-very-large );
    box-sizing: border-box;
}

.formulaContent {
    position: relative;
    margin-top: calc( var( --space-large ) + 24px );
}

.formulaInputCont {
    position: relative;
}

.formulaInputToolbar {
    position: absolute;
    top: 0; left: 0; right: 0;
    height: 48px;
    border-bottom: var( --border-main );
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.formulaInput {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    resize: none;
    border: var( --border-main );
    border-radius: var( --corner-radius-default );
    padding: var( --space-medium );
    padding-top: 64px;
    font: var( --font-code );
}

.label {
    font: var( --font-body-small );
}

.outputArea {
    padding: var( --space-medium );
    margin-top: 6px;
    font: var( --font-code );
    border: var( --border-main );
    border-radius: var( --corner-radius-default );
    overflow: hidden;
    white-space: pre-wrap;
    direction: ltr;
}

.categoriesCont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 6px -2px 16px -2px;
}

.categoryChipCont {
    position: relative;
    margin: 2px;
}

.categoryPropsCont {
    display: flex;
}

.pickerCont {
    display: flex;
    max-width: 256px;
    flex-wrap: wrap;
    max-height: 40vh;
    overflow-y: auto;
}

.operatorBtn{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    min-width: 32px;
    height: 32px;
    margin: 2px;

    font: var( --font-code-bold );
    cursor: pointer;
}

.operatorBtn:hover {
    background-color: var(--color-primary-light);
}

.mathMethodName {
    font: var( --font-code-bold );
    color: var( --color-type-high-emphasis );
}

.mathMethodDescription {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
}

/*************/
/* ARGUMENTS */
/*************/

.argsArea {
    position: relative;
    width: 25%;
    padding: var( --space-very-large );
    box-sizing: border-box;
}

.argsCont {
    position: absolute;
    /* margin-top: calc( var( --space-large ) + 24px ); */
    top: calc( var( --space-large ) + 98px );
    left: 0; right: 0; bottom: 0;
    box-sizing: border-box;
    overflow-y: auto;
    padding-bottom: var( --space-large );
}

.argItemCont{
    position: relative;

    border: var( --border-main );
    border-radius: var( --corner-radius-default );
    margin-bottom: 8px;
}

.argNameLabel {
    font: var( --font-code-bold );
    margin: 0 4px;
    width: 100%;
}

.argItemTopCont {
    position: relative;
    margin: 8px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.argsSettingsCont {

    padding: var( --space-small ) calc( 2 * var( --space-small ) );
}

.divider {
    border-top: var( --border-light );
}

.dropdownKeyLabel {
    font: var( --font-body-medium );
}

.dropdownTypeLabel {
    color: var( --color-type-low-emphasis );
}

.dropdownTitleLabel {
    color: var( --color-type-medium-emphasis );
    font: var( --font-body-small );
}

.valuesDictRow {
    position: relative;
    display: flex;
    margin-bottom: 4px;
}

.valuesDictValueLabel {

    padding: 8px 16px;
    box-sizing: border-box;
    min-width: 50%;
    width: 50%;
    border: var( --border-main );
    border-radius: var( --corner-radius-default );
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.valuesDictValueLabel:active {
    z-index: 2;
    min-width: fit-content;
    background-color: var( --color-background-mild );
}
