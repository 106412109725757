.subtitle {
    font: var( --font-body );
    margin-bottom: var( --space-small );
    min-width: 300px;
}

.editableParagraph {
    box-sizing: border-box;
    width: 100%;
    min-width: 300px;
    padding: 1px;
    overflow: auto;
    font: var( --font-body );
    resize: none;
}

hr {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: var( --color-border-light )
}