#additionalContent{
    margin-bottom: var(--space-medium);
}

.title {
    display: block;
    font: var(--font-headline-h2);
}

.message {
    white-space: pre-wrap;
    white-space: pre-line;
}

#actionsCont{
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-medium);
}