.mainContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-background-mild);
    padding: var(--space-large) 70px 0 70px;
    overflow: hidden;
}

.recordsTableArea {
    position: relative;
    /* display: flex;
    flex-direction: column; */
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
    padding-bottom: var(--space-medium);
}

#recordsNumber {
    margin: 0px 0 10px 0;
}

.responseNumber {
    font: var(--font-body-small);
    color: var(--color-type-low-emphasis);
}

#reloadingCont {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background-color: rgba( 255,255,255,0.8 ); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.loadingHeadline {
    font: var(--font-headline-h2);
    color: var(--color-type-medium-emphasis);
    margin-top: 48px;
    margin-bottom: 10px;
}

.topOptionBar {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.bottomOptionBar {
    display: flex;
    flex-direction: row;
}

h2 {
    margin-top: 0px;
    margin-bottom: 24px;
    font: var(--font-headline-h2);
}

.reloadingCont {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin: 12px;
}

.noAnalysesYet {
    margin: 12px;
}

/* Records selection options*/
.recordsSelectionOptionsCont {
    position: absolute;
    top: -12px;
    right: 0;
    left: 0;
    height: 48px;
    z-index: 2;
    background-color: var(--color-background-mild);
    box-shadow: 0 16px 16px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.numberOfSelectedRows {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    margin-inline-end: 24px;
    color: var(--color-type-medium-emphasis);
    border-inline-end: var(--border-main);
}
