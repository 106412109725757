#list {
    list-style-type: none;
    margin: 0;
    padding: 0;

}

#listContainer {
    max-height: 80vh;
    overflow-y: auto;  
    
}

.listItem {
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.listItem:hover {
    background-color: var(--color-primary-light);
}

.typeIcon {
    width: 24px;
    height: 24px;
}

.gap {
    width: 8px;
}