.rootCont {
    position: absolute;
    left: 0;  top: 0; right: 0;
    z-index: 20;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: var( --border-light );
    padding: 0px var( --space-small );
    background-color: var( --color-background-light );
    /* filter: var( --shadow-extra-low ); */
}

.logoSection {
    display: flex;
    align-items: center;
    min-width: 300px;
    /* background-color: rebeccapurple; */
}

.barEndCont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    /* background-color: rosybrown; */
}

.projectName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navButtons {
    /* position: absolute;
    top: 0; right: 50%; bottom: 0; left: 50%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.border {
    border-right: var( --border-light );
    width: 2px;
    height: var( --space-medium );
    margin: 8px;
}

.gap {
    width: 18px;
}

.navLink {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0px 16px 0px 16px;
    padding: 2px 2px 2px 2px;
    font: var( --font-btn );
    color: var( --color-background-dark );
    text-decoration: none;
    flex-shrink: 0;
}

.navLink:hover {
    color: var( --color-type-high-emphasis );
}

.logo {
    font: var( --font-headline-h2-bold );
    margin: 0;
    cursor: pointer;
    color: var( --color-type-high-emphasis );
}