.pageContainer {
    position: relative;
    padding: 16px var( --space-large );
    padding-bottom: 48px;
    display: flex;
    align-items: flex-start;
    overflow: visible;
}

.selectedIndicator {
    position: absolute;
    top: -21px; bottom: 19px; 
    right: 12px; left: 12px;
    width: 12px;
    z-index: 0;
}

.itemTopCont {
    display: flex;
    align-items: center;
    z-index: 1;
}

.itemIcon {
    width: 32px; height: 32px;
}

.blockIcon {
    width: 32px; height: 32px;
    margin: -16px 0;
    margin-inline-start: 16px;
}

.itemIndexLabel {
    margin: 0px 16px;
    font: var( --font-headline-h2 );
    user-select: none;
}

.dragHandlePageCont {
    position: absolute;
    top: 64px; 
    left: calc( 7px + var( --space-large ) ); 
    right: calc( 7px + var( --space-large ) ); 
    max-width: fit-content;
}

.dragHandle {
    --border: 1px solid var( --color-type-medium-emphasis );

    width: 18px; height: 8px;
    border-top: var( --border );
    border-bottom: var( --border ); 

    cursor: grab;
}

.dragHandle:focus {
    cursor: grabbing;
    /* border: none; */
}

.moduleCont {
    flex-grow: 2;
    padding: 0 12px;
    overflow: hidden;
    z-index: 1;
}

.addBtnCont {
    --size: 40px;
    z-index: 0;
    position: absolute;
    bottom: 0; 
    right: calc( 100% - 64px ); 
    left: calc( 100% - 64px );
    height: var( --size ); width: var( --size );
    /* z-index: 0; */
    border-radius: 50%;
    background-color: var( --color-background-light );
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionsBtnCont {
    --size: 32px;

    position: absolute;
    top: calc( var( --size ) * 0.5 ); 
    right: calc( 100% - 60px ); 
    left: calc( 100% - 60px );
    height: var( --size ); width: var( --size );
    z-index: 2;
    border-radius: calc( var( --size ) * 0.5 );
}


.separationLine {
    position: absolute;
    right: var( --space-medium ); bottom: 20px; left: var( --space-medium );
    height: 1px;
    background-color: var( --color-border-light );
}

.titleInput {
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    overflow: auto;
    font: var( --font-headline-h3 );
    margin-bottom: var( --space-small );
    padding-inline-end: 24px;
    box-sizing: border-box;
}


/* Block */
.blockContainer {
    position: relative;
    padding: 16px 0;
    padding-bottom: 48px;
    margin: 16px 0;
    overflow: visible;
}

.blockFrame {
    position: absolute;
    top: 0; right: var( --space-small ); bottom: 20px; left: var( --space-small );
    pointer-events: none;
    border: var( --border-main );
    border-radius: var( --corner-radius-large );
    overflow: hidden;

}

.blockTopCont {
    position: relative;
    display: flex;
    margin: 0 var( --space-large );
    align-items: center;
}

.blockInnerCont {
    position: relative;
    padding: var( --space-small );
    padding-top: 24px;
}

.dragHandleBlockCont {
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blockTitleCont {
    flex-grow: 2;
    padding: 0 12px;
    overflow: hidden;
    width: fit-content;
    block-size: fit-content;
}

.addPageInternalyBtnCont {
    margin: 0 var( --space-large );
    margin-top: var( --space-medium );
}

.selectedBlockIndicator {
    position: absolute;
    top: 0; bottom: 0; 
    right: 0; left: 0;
    width: 12px;
    z-index: 0;
}

.blockOptionsBtnCont {
    --size: 32px;

    position: relative;

    height: var( --size ); width: var( --size );
    z-index: 2;
    border-radius: calc( var( --size ) * 0.5 );
}