.versionsCont {
    padding: 16px;
    border-radius: var( --corner-radius-default );
    background-color: rgba(255, 255, 255, 0.8);
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.versionsSectionLabel {
    margin: 24px 0 4px 0;
    font: var( --font-body-small );
}

.versionItemCont {
    position: relative;
    height: 36px;
    border-bottom: var( --border-light );
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.dateLabel{
    font: var( --font-body-small );
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gap{
    width: 8px;
}
