.mainCont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
}

.sideCol {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 360px;
}

.itemEditorCont {
    position: relative;
    flex: 2;
    border-width: 0 1px;
    border: var(--border-light);
}

/* PAGE LOGIC ITEMS */
.pageLogicItemsListCont {
    flex: 2;
    border-bottom: var(--border-light);
    padding: var(--space-large);
    padding-top: var(--space-small);
    overflow: auto;
}

/* VARIABLES LIST */
.variablesListCont {
    flex: 1.5;
    padding: var(--space-large);
    padding-top: var(--space-small);
    min-height: 200px;
    overflow: auto;
    background-color: var(--color-background-mild);
}
