
.modalPanel {
  
    position: absolute;
    top: 48px; right:10%; bottom:0; left:10%;
    max-width: unset;
    max-height: unset;
    border-radius: var(--corner-radius-default) var(--corner-radius-default) 0 0;
    padding:0;
    overflow:hidden;
    /* background-color: var(--color-background-mild); */
}

.storyIframe{
    border: none;
    width: 100%;
    height: 100%;
}