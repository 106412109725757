.newItemBtnCon {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 36px;

}

.listWrapper {
    position: relative;
    min-width: fit-content;
    border-radius: var( --corner-radius-default );
    border: var( --border-light );

    overflow: hidden
}

.listCont {
    position: relative;
    min-width: fit-content;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.virtualizedItemCont {
    margin: 0 12px;
    max-width: calc( 100% - 24px );
}

.itemCont {
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: 36px;
    margin-bottom: 2px;
    border-radius: var(--corner-radius-default);
    padding: 4px 0;
    box-sizing: border-box;
    /* overflow: hidden; */
}



.itemCont:hover {
    background-color: var( --color-background-hover );
}

.bullet {
    font: var( --font-body-medium );
    margin: 6px var( --space-small ) 0;
    user-select: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16px;
}

.itemText {
    background-color: transparent;
    font: var( --font-body-medium );
    flex-grow: 3;
    max-width: 60%;
    min-width: 200px;
    margin-inline-end: 16px;
    box-sizing: border-box;
}

.itemKey {
    direction: ltr;
    background-color: transparent;
    font: var( --font-code );
    font-size: 12px;
    color: var( --color-type-low-emphasis);
    max-width: 20%;
    flex-grow: 1;
    text-align: center;
    margin: 6px 4px 0;

    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}

.optionsRefCont {
    margin-top: 2px;
    margin-inline-end: 2px;
}

.gotoPageRefCont {
    position: absolute;
    top: -4px; right: calc( 100% ); left: calc( 100% );
    pointer-events: none;
}