.mainContainer {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;  
    justify-content: center;
    padding: var(--space-large);
    overflow-y: auto;
}

.innerContainer {
    max-width: 1200px;
    width: calc(100vw - 48px);
    margin: 0px 24px 0px 24px;
}

.filterContainer {
    display: flex;
    margin-bottom: 60px;
}

.headline {
    margin-bottom: 10px;
    font: var(--font-headline-h2);
}

.subheadlineContainer {
    display: flex;
    justify-content: flex-end;
}

.subHeadline {
    font: var( --font-body-small );
    color: var( --color-type-high-emphasis );
    text-align: center;
    width: 144px;
    min-width: 144px;
}

.subHeadlineTime {
    font: var( --font-body-small );
    color: var( --color-type-high-emphasis );
    text-align: center;
    width: 86px;
    min-width: 86px;
    margin: 0px 36px 0px 20px;
}

.actionBarContainer {
    display: flex;
    padding: 24px 0px 24px 0px;
    margin-bottom: 24px;
    border-bottom: var( --border-light );
}

.surveyStatsContainer {
    display: flex;
    margin-bottom: 60px;
}

.statItem {
}

.statHeadline {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    margin-bottom: 10px;
}

.surveyStat {
    font: var( --font-number );
    color: var( --color-type-high-emphasis );   
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 500px);
}

.loadingHeadline {
    font: var( --font-headline-h2 );
    color: var( --color-type-medium-emphasis );
    margin-top: 48px;
    margin-bottom: 10px;
}

.loadingSubHeadline {
    font: var( --font-body );
    color: var( --color-type-low-emphasis );
}

.gap {
    width: 88px;
}

#headlineCont {
    display: flex;
    justify-content: space-between;
}

#noteCont {
    display: flex;
}

#responseNumber {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin: 8px 16px 0px 16px;
}

#graphBtnCont {
    display: flex;
    margin-bottom: 40px;
}

.mainGraphContainer {
    position: relative;
    
    margin: 0px 0px 48px 0px;
}

#graphCont {
    position: relative;
    height: 200px;
    width: 100%;
}
