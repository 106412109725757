.mainContainer {
    position: relative;
    display: flex;
    margin: 2px 0px 2px 0px;
    border-radius: var( --corner-radius-default );
    align-items: center;
    padding: 6px 0px 6px 0px;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    background-color: transparent;
    font: var( --font-btn );
    cursor: pointer;
}

.mainContainer:hover {
    background-color: var( --color-background-mild );
}