.container {
    z-index: 999;
    position: absolute;
    bottom: var( --space-medium );

    height: 48px;
    width: fit-content;
    border-radius: var( --corner-radius-default );
    background-color: var( --color-background-xxdark );

    box-sizing: border-box;
    padding: var( --space-small ) 0;

    display: flex;
    align-items: center;

    overflow: hidden;
}

.gap {
    width: 16px;
}

.messageLabel {
    font: var( --font-body );
    color: white;
    white-space: nowrap;
}

.sideIconCont {
    box-sizing: border-box;
    height: calc( 100% + 2 * var( --space-small ) );
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.timeoutFill {
    position: absolute;
    top: 0; right:0; bottom: 0; left: 0;
    background-color: rgba(255,255,255,0.1);
    pointer-events: none;
}