.pageItemCont {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pageNumber {
    font: var( --font-body-bold );
    margin: 0 8px;
}