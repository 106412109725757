.pageMainContainer {
    display: flex;
    border-bottom: var( --border-light );
    padding: 32px 0px 32px 0px;
}

.number {
    font: var( --font-number );
    color: var( --color-type-high-emphasis );
    width: 20px;
    margin: 4px 0px 0px 0px;
    text-align: center;
}

.pagestatsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    align-self: flex-end;
    flex-grow: 1;
    max-width: calc( 100% - 205px );
}

.dropOffContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    direction: ltr;
}

.dropOff {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    width: 128px;
    min-width: 128px;
    padding: 8px ;
    margin-top: 4px;
    text-align: center;
    border-radius: var( --corner-radius-default );
    align-self: flex-start;
    display: flex;
}

#dropOffRateCont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

#dropOffRatioCont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-left: var( --border-light );
    margin-left: 8px;
    padding-left: 8px;;
    /* border-color: var( --color-type-low-emphasis ); */
}



