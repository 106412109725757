.mainCont {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
}  

.iconsCont {
    position: absolute;
    top: 0; right: 8px; bottom: 0; left: 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
}

.gap {
    height: 100%;
    width: var( --space-medium )
}

.label {
    display: block;
    font: var( --font-body-small );
    margin-bottom: 4px;
    white-space: nowrap;
}

.fieldCont {
    position: relative;
}

.indicationCont {
    position: absolute;
    width: 36px; 
    /* height: 36px; */
    right: calc( 100% - 36px ); top: 0px; bottom: 0px; left: calc( 100% - 36px );
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    /* padding: 6px 16px; */
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    border-radius: var( --corner-radius-default );
    font: var( --font-body-medium );
    border: none;
    background-color: none;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 0pt 3pt var(--color-primary-light );
    border-radius: 2pt;
}

::placeholder {
    color: var( --color-type-disabled );
}

.withIconBefore {
    padding-inline-start: 40px;
}

/* input, select, textarea {
    border: 9px solid var( --color-primary-light );
} */


/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    font: var(--font-body);
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
    border-radius: var( --corner-radius-default );
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

/* Removes the clear button from date inputs */
input[type="datetime-local"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="datetime-local"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for datetime-local inputs */
input[type="datetime-local"] {
    appearance: none;
    -webkit-appearance: none;
    font: var(--font-body);
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
    border-radius: var( --corner-radius-default );
}

input[type="datetime-local"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
