.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
}

.listContainer {
    max-height: 40vh;
    overflow-y: auto;  
    
}

.listItem {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;

}

.listItem:hover {
    background-color: var(--color-primary-light);
}

.gap {
    width: 12px;
}

.loadingCont {
    display: flex;
    align-items: center;
    font: var( --font-body-medium );
    color: var( --color-type-low-emphasis );
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
}
