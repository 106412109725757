#mainCont {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-background-mild);
    display: flex;
    justify-content: center;
    align-items: center;
}

#elementsCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    margin-bottom: 100px;
}