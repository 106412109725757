.mainCont {
    padding-left: 20px ;
    margin-bottom: 50px;
}

.labelHeadline {
    font: var( --font-body-medium );
    color: var( --color-type-medium-emphasis );
    margin: var( --space-medium ) 0px var( --space-small ) 0px
}

.rowCont {
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(350px, 1fr));
    grid-template-rows: 1;
    grid-gap: 16px;
}

.rowCard {
    background-color: cornflowerblue;
    position: relative;
    height: 150px;
    box-sizing: border-box;
    hyphens: auto;
    background-color: var( --color-background-light );
    border-radius: var( --corner-radius-large );
    display: flex;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.rowCard:hover {
    box-shadow: var( --box-shadow-low );
}

.activityIndicatorCont {
    position: relative;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maxProjectsIndicatorCont{
    position: relative;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var( --color-type-low-emphasis )
}