.tooltip {
    background-color: var( --color-background-dark );
    padding: 4px 8px;
    border-radius: 4px;
    /* transition: opacity 0.25s ease-out;
    transition-delay: 0.3s; */
    pointer-events: none;
    z-index: 999;
    max-width: 250px;
}

.label {
    color: #ffffff;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.arrow::before {
  content: '';
  transform: rotate(45deg);
  background: var( --color-background-dark );
}

.tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
}