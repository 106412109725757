.analysesMainCont {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
}

.analysesSplitCont {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    overflow: hidden;
}

/*** SIDE PANEL ***/

.sidePanelCont {

    height: 100%;
    box-sizing: border-box;
    padding: var( --space-large );
    padding-top: var( --space-small );
    background-color: var( --color-background-mild );
    overflow-y: auto;
    border: var( --border-light );
}

.loaderCont {
    display: flex;
    justify-content: center;
}

.sidePanelTitleLabel {
    font: var( --font-body-small-medium );
    color: var( --color-type-medium-emphasis );
    line-height: 2.5em;
    margin: 0 0 24px 0;
    border-bottom: 4px double var( --color-type-medium-emphasis );
}

.sidePanelLine {
    border-bottom: var( --border-light );
    margin: var( --space-medium ) calc( -1 * var( --space-large ) );
}

.sidePanelFieldCont {
    margin-top: 8px;
}

.descriptionCont {
    margin-top: var( --space-small );
    display: flex;
    align-items: center;
}

.shortDescription {
    display: inline-block;
    max-height: 2.7em;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;

}

.descriptionDialogCont {
    /* max-width: 33vw; */
}

.descriptionDialogCont p{
    font: var( --font-body );
}

.categoryTopCont {
    display: flex;
    align-items: center;
    margin-top: var(--space-small);
    margin-bottom: 8px;
}

.varCategoryTitle {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    border-bottom: var(--border-light);
    user-select: none;

}

.varFieldCont {
    position: relative;
    margin-right: 24px;
    margin-left: 24px;
    margin-top: 16px;
}

.mandatoryIndicator {
    position: absolute;
    top: 0; right: -24px; left: -24px;
    width: fit-content;
    color: var( --color-primary-medium );
    user-select: none;
}

.tooltipBtn {
    position: absolute;
    top: 0; 
    right: calc( 100% ); 
    left: calc( 100% );
    width: fit-content;
    user-select: none;
    cursor: help;
}

.submissionLock {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    height: 100%;
    background-color: var( --color-background-mild );
    opacity: 0.7;
}

/*** CONTENT AREA ***/

.noAnalysesCont {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentCont {

    position: relative;
    flex: 3;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background-color: var( --color-background-mild );
    
}

.contentTopBar {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var( --space-small );
    border-bottom: var( --border-light );

}

.contentItemsCont {
    position: relative;
    max-height: calc( 100% - 74px );
    box-sizing: border-box;
    padding: var( --space-large );

    overflow-y: auto;
    
}

.noAnalysesYet {
    margin: 12px;
}

.contentActivity {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    background-color: var(--color-background-mild);
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentFooterLoadingCont {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}