.mainCont {
    padding-left: 20px ;
    margin-bottom: 40px;
}

.labelHeadline {
    font: var( --font-body-medium );
    color: var( --color-type-medium-emphasis );
    margin: var( --space-medium ) 0px var( --space-small ) 0px
}

.rowCont {
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 6px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    grid-template-rows: 1;
    grid-gap: 16px;
    overflow-y: auto;
}

.rowItem {
    position: relative;
    height: 70px;
    box-sizing: border-box;
    padding: 0 8px;
    hyphens: auto;
    background-color: var( --color-background-mild );
    border-radius: var( --corner-radius-large );
    border: var( --border-main );
    display: flex;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    /* white-space: nowrap;
    text-overflow: ellipsis; */
    
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

.rowItem:hover {
    background-color: var( --color-background-light );
    box-shadow: var( --box-shadow-low );
}


.newItemLabel {
    margin-inline-start: 16px;
}

