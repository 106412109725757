.mainCont{
    position: relative;
    margin-top: var( --space-medium );
}

/* ADD BUTTON */
.addFragmentBtnCont {
    margin: 12px 8px;
}

.groupIcon {

    display: inline-block;
    border-radius: 12px;
    border-width: 0px 3px;
    border-style: solid;
    border-color: var( --bg-col ) var( --color-type-medium-emphasis );
    box-shadow: var( --box-shadow-low );
    text-align: center;
    padding: 0 6px;
    margin-inline-end: 12px;
}

.ruleIcon {
    width: 12px;
    display: inline-block;
    text-align: center;
    margin-inline-end: 12px;
    padding: 0 6px;
    font: var( --font-code-bold );
}

/* GROUP */
.groupCont {
    --bg-col: rgba( 255,255,255,0.7 );

    /* direction: ltr; */
    min-width: fit-content;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 16px 0;
    padding: 16px;
    background-color: var( --bg-col );
    border-radius: 24px;
    border-width: 1px 3px;
    border-style: solid;
    border-color: var( --bg-col ) var( --color-type-medium-emphasis );
    box-shadow: var( --box-shadow-low );
}

.groupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logicOperatorIcon {
    font: var( --font-code-bold );
    color: var( --color-primary-medium );
    border-radius: 16px;
    padding: 4px;
    margin-inline-end: 4px;
}

/* RULE */
.ruleCont {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    padding: 8px 12px;
    overflow: hidden;
    
    border-radius: 0;
    border-bottom: 1px solid var( --color-type-low-emphasis );

}

.ruleComparisonCont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    direction: ltr;
    width: 100%;
}

.comparisonBtnCont {
    margin: 0 4px;
}

.comparisonIcon {
    direction: ltr;
    display: inline-block;
    font: var( --font-code-bold );
    margin-inline-end: 8px;
    width: 32px;
    text-align: center;
}
