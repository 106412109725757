.mainContainer {
    display: flex;
    flex-direction: row;
}

.identifierContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-content: space-between;
    margin: 0px 24px 0px 24px;
}

.questionText {
    font: var( --font-body );
    color: var( --color-type-medium-emphasis );
    margin: 10px 32px 0px 32px;
    flex-grow: 1;

    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;

    flex-basis: 0;
    flex-shrink: 4;
}

.time {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    width: 72px;
    padding: 8px;
    text-align: center;
    margin: 4px 24px 0px 24px;
}

.dropOff {
    font: var( --font-body-small );
    color: var( --color-type-medium-emphasis );
    align-self: flex-start;
    width: 128px;
    min-width: 128px;
    padding: 8px ;
    margin-top: 4px;
    text-align: center;
    border-radius: var( --corner-radius-default );
}