.mainCont {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;

}

.previewFrameCont {
    position: absolute;
    top: 48px; right: 0; bottom: 48px; left: 0;
    padding: var(--space-large);
    display: flex;
    justify-content: center;
    align-items: center;
}

.surveyIframe {
    margin-top: 16px;
    /* width: 360px; */
    /* height: 640px; */
    resize: both;
    border: var( --border-light );
    border-radius: var( --corner-radius-default );
}

/* TOP BAR */
.topBarCont {
    width: 100%;
    height: 56px;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.previewBtnCont {
    justify-self: flex-end;
}

.previewCodeLabel {
    color: var( --color-type-low-emphasis );
    text-align: end;
    margin-top: 8px;
}

.previewCode {
    font: var( --font-code );
}