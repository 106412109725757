.mainCont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-background-mild);
    padding: var(--space-large);
    padding-top: var(--space-small);
    min-width: 200px;
    overflow-y: auto;
}

.loaderCont {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--color-background-mild);
}

.modeLabel {
    font: var(--font-body-small-medium);
    color: var(--color-type-medium-emphasis);
    line-height: 2.5em;
    margin: 0 0 24px 0;
    text-transform: uppercase;
    white-space: nowrap;
    /* border-bottom: 4px double var( --color-type-medium-emphasis ); */
}

.sectionsLabel {
    font: var(--font-body-small);
    color: var(--color-type-low-emphasis);
    margin: 8px 0 16px 0;
}

.collapseIconCont {
    position: absolute;
    top: var(--space-small);
    right: calc(100% - 40px);
    left: calc(100% - 40px);
    width: 28px;
    /* background-color: var(--color-background-light); */
    border-radius: 50%;
}

.infoLabel {
    font: var(--font-code);
    font-size: 14px;
    color: var(--color-type-medium-emphasis);
    margin-bottom: 14px;
}

.separationLine {
    height: 1px;
    margin: var(--space-medium) -32px;
    background-color: var(--color-border-light);
}

.checkbox {
    margin-bottom: 12px;
}

.dropdown {
    margin-bottom: 16px;
}

.textInput {
    background-color: white;
}
