.inputCont {
    margin: 0px;
    width: 50%;
    max-width: 500px;
    /* margin-bottom: var( --space-medium ); */
}

.inputMessage {
    
    margin-inline-start: 8px;
    color: var( --color-type-low-emphasis );
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;   
}

.listContainer {
    max-height: 40vh;
    overflow-y: auto;  
}

.iconSpan {
    padding: 5px;
}

.nameSpan {
    font: var( --font-body-medium );
    padding: 5px;
}

.sourceNameSpan {
    padding: 5px;
    color: var( --color-type-low-emphasis );
}

.listItem {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.listItemHighlight {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    background-color: var(--color-primary-light);
}

