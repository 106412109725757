.editable {
    border: none;
    padding: 0;
    box-sizing: border-box;
    /* width: 100%; */
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    white-space:nowrap; 
    overflow:hidden;
    text-overflow:ellipsis;

}

.editable:focus {
    outline: none;
}

.editableTextArea {
    border: none;
    padding: 0;
    box-sizing: border-box;
    /* width: 100%; */
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

}

.editableTextArea:focus {
    outline: none;
}