.sectionCont {
    position: relative;
}

.preToolsCont {
    position: absolute;
    top: 6px; 
    left: -72px; 
    right: -72px; 
    width: 72px;
    display: flex;
    align-items: center;
}

.dragHandle {
    --border: 1px solid var( --color-type-low-emphasis );

    width: 18px; height: 8px;
    border-top: var( --border );
    border-bottom: var( --border ); 
    margin: 0 10px;
}

.headerCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sectionTitle {
    font: var( --font-headline-h2-bold );
    margin-bottom: 0;
    flex:1;
}

.editorCont {
    margin-top: 6px;
}

.editorCont p {
    font: var( --font-body );
    line-height: 1.5em;
}

.editorCont h3 {
    font: var( --font-headline-h3 );
    line-height: 1.5em;
    margin-bottom: 2px;
}

.editorCont sup {
    line-height: 1.5em;
    vertical-align: middle;
}

.editorCont div:focus {
    outline: none;
}

.footerCont {
    position: relative;
    margin-top: var( --space-medium );
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    border-top: var(--border-light);
}

.newSectionBtnCont {
    position: absolute;
    top: -20px;
    left: calc( 100% - 40px );
    right: calc( 100% - 40px );
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var( --color-background-light );

}

.optionsBtnCont {
    margin: 0 6px;
}