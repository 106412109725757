.mainCont {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: var(--color-background-mild);
    padding: var( --space-very-large );
    padding-bottom: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageCont {
    position: relative;
    flex: 1;
    padding: var( --space-very-large ) 96px;
    box-sizing: border-box;
    background-color: var( --color-surface );
    min-width: 880px;
    width: 66%;
    max-width: 1024px;
    border-top-left-radius: var( --corner-radius-very-large );
    border-top-right-radius: var( --corner-radius-very-large );
    box-shadow: var( --box-shadow-low );
}

.lockContainer {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    background-color: rgba( 255,255,255,0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
}

.lockPanel {
    background-color: var( --color-background-extradark );
    padding: 8px 18px 12px 18px;
    border-radius: var( --corner-radius-default );
    color: white;
    align-self: flex-start;
    margin-top: var( --space-small );
    font: var( --font-body );
    display: flex;
    justify-content: center;
    align-items: center;
}

/*///// TOOLBARS /////*/

.autoSaveIndicator {
    position: absolute;
    top: var(--space-small);
    left: calc( 100% - 54px - var(--space-small));
    right: calc( 100% - 54px - var(--space-small));
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.autoSaveLabel {
    color: var(--color-type-low-emphasis);
    margin: 0 8px;
}

.pageDirection {
    position: absolute;
    top: var(--space-small);
    left: calc( var(--space-small));
    right: calc( var(--space-small));
    width: 40px;
}

/*///// HEADER /////*/

.headerCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notebookDetailsCont {
    flex: 2
}

.headerIconCont {
    /* flex: 1; */
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background-mild );
    border-radius: 50%;
}


.notebookTitle {
    font: var( --font-headline-h1 );
    margin-bottom: var(--space-medium);
    width: 100%;
    margin-inline-end: 16px;
}

.headerDetail {
    color: var(--color-type-low-emphasis );
}

.headerSeparator {
    margin: var( --space-very-large ) -96px;
    height: 3px;
    background-color: var(--color-background-mild);
}

/*///// CONTENT /////*/

.blankNotebookCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
 .blankMessage {
     margin-bottom: var(--space-small);
 }