

.mainCont {
    background-color: var(--color-background-mild);
    overflow: scroll;
    max-height: 100%;
}

.loadingCont {
    display: grid;
    place-items: center;
    height: 90%;
}

.lightboxCont {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0,0,0,0.6);
    display: grid;
    place-items: center;
    animation: fadein 0.2s backwards;
}

@keyframes fadein {  
  from { opacity: 0 }
  to { opacity: 1 }
}

.lightboxCard {
    direction: ltr;
    display: grid;
    grid-template-columns: 45vw 35vw;
    grid-template-rows: 70vh;
    grid-template-areas: "image" "text";
    border-radius: 18px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.9);
    text-align: center;
    transition: 0.2s ease;
    /* cursor: pointer; */
    background-color: var(--color-background-light); 
}

.lightboxImage {
    grid-area: 'image';
    border-right-width: 1px;
    border-right-color: lightgrey;
    border-right-style: solid;
    background-color: white;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.lightboxText {
    background-color: var(--color-background-mild);
    grid-area: 'text'; 
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: auto;
    grid-template-areas: "date"
                         "main"
                         "actions"
                         "project";

}

.lightboxTitle {
    grid-area: main; 
    font: var( --font-headline-h2 );
    color: var( --color-type-medium-emphasis );
    /* margin: 100px 32px 30px 32px; */
}
.lightboxNotes {
    grid-area: main;
}
.lightboxProject {
    grid-area: project;
    /* background-color: blue; */
    display: grid;
    /* place-items: center; */
    height: 30px;
}
.lightboxDate {
    grid-area: date;
    color: var( --color-type-low-emphasis );
    display: grid;
    place-items: center;
    height: 60px;
}
.lightActions {
    grid-area: actions;

    display: flex;
    justify-content: space-between;
    padding: 30px;
}
/* .lightActionItem {
    grid-area: blat;
} */
.lightboxCloseBtn {
    position: fixed;
    padding: 8px;
}

.previousBtn {
    position: absolute;
    top: 50%;
    left: 7vh;
}

.nextBtn {
    position: absolute;
    top: 50%;
    right: 7vh;
}

.pageTitle {
    font: var( --font-headline-h3 );
    color: var( --color-type-medium-emphasis );
    margin: 10px 32px 10px 32px;
    display: flex;
    
}

.cardsGalleryRow {
    max-height: 100%;
}

.rowTitle {
    font: var( --font-headline-h3 );
    color: var( --color-type-high-emphasis );
    padding-inline-start: 30px;
    padding-block-start: 10px;
}

.rowGalleryCont {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    padding-top: 15px;
    justify-content: first baseline;
    margin-bottom: 15px;
    grid-gap: 25px;
    gap: 25px;
    overflow-x: auto;
}

.card {
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 250px 150px 30px;
    grid-template-areas: "image" "text" "stats";
    border-radius: 18px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.9);
    /* text-align: center; */
    margin-bottom: 20px;
    transition: 0.2s ease;
    cursor: pointer;
    background-color: var(--color-background-light);
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 5px 5px 15px rgba(0,0,0,0.6);
}

.cardImage {
    grid-area: image;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: white;
    background-size: contain;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    border-bottom-width: 0.5px;
    border-bottom-style: dashed;
    border-bottom-color: lightgrey;
    padding:30px;
}
.cardNoteText {
    grid-area: text;
    margin: 5px;
    padding: 10px;
}

.cardNameTitle {
    font: var( --font-headline-h3 );
    color: var( --color-type-high-emphasis );
    margin-bottom: 10px;
    margin-top: 10px;
}

.date {
    font: var( --font-body-extra-small );
    color: var( --color-type-medium-emphasis );
}
.icon {
    margin: 10px;
}



.cardStats {
    grid-area: stats;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    border-top-color: lightgrey;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: var(--color-background-mild)
}

.cardStats .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


/* OK */
.chipsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

}
/* OK */
.chipContainer {
    margin-inline-end: 6px;
    margin-bottom: 0px; 
}