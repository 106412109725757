.bottomStiky {
    position: sticky;
    bottom: -32px;
    background-color: var(--color-background-mild);
}

.versionsTable {
    border: none;
    background-color: var(--color-background-light);
    border-radius: 4px;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
}

.versionRowCont {
    border-bottom: var(--border-light);
}

.versionRowCont > td {
    padding: 8px;
}

.versionDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.versionDate {
    color: var(--color-type-low-emphasis);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline-end: 16px !important;
}

.versionsSectionLabel {
    margin: 24px 0 4px 0;
    font: var(--font-body-small);
}

.restoreBtnCell {
    opacity: 0.2;
    padding-left: 4px;
    padding-right: 4px;
}

.versionRowCont:hover .restoreBtnCell {
    opacity: 1;
}
