.loginContainer {
    display: flex;
    background-color: var(--color-background-light);
    width: 100vw;
    height: 100vh;
}

.sideOneCont {
    background-color: black;
    background-color: var(--color-primary-light);
    padding: 48px;
    flex-grow: 1;
}

.sideTwoCont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 400px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

#loginForm {
    margin: -48px 48px 48px 48px;
}

#errorLabel {
    font: var(--font-body-small);
    color: var(--color-error-medium);
}

#forgotPassword {
    font: var(--font-body-small);
    color: var(--color-primary-medium);
    margin: 0px 0px -22px 0px;
    text-align: right;
}

#getIntouchLabel {
    font: var(--font-body-small);
    color: var(--color-type-low-emphasis);
    margin: 24px 0px 0px 0px;
}

.passwordCont {
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-direction: column;
}

#headlineLabel {
    font: var(--font-headline-h2);
    margin: 0px 0px 54px 0px;
}

#logoCont {
    font: 40px/42px 'Ploni';
    font-weight: 700;
    margin-bottom: 40px;
}

#subHeadlineLabel {
    font: 36px/46px 'Ploni', sans-serif;
    font-weight: 400;
    color: var(--color-type-medium-emphasis);
    width: 550px;
}

.versionLabel {
    position: absolute;
    bottom: 48px;
    left: 48px;
    margin: 0;
}
