.collapseTitleCont {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.collapseTitle {
    margin-inline-start: 4px;
    font: var(--font-body-small);
    cursor: pointer;
}

.customTextCont {
    padding-bottom: var(--space-small);
    margin-bottom: var(--space-small);
    border-bottom: var(--border-light);
}

.customTextInput {
    margin-top: 4px;
}
