/* //// NORMAL SELECTION POPPER ////// */

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex:1;
}

.listContainer {
    max-height: 40vh;
    overflow-y: auto;  
    
}

.listItem {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font: var(--font-btn )
    
}

.listItem:hover {
    background-color: var(--color-primary-light);
}

.listItem:active {
    background-color: var(--color-primary-light);
}


/* //// CASCADING SELECTION POPPER ////// */

.subOptionsCont {
    max-height: 40vh;
    overflow-y: auto;  
}

.listItemLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}