.mainCont {
    padding: 12px 24px 12px 24px;
}

.title {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin-bottom: 24px;
    /* margin: var( --space-medium ) 0px var( --space-small ) 0px */
}

#itemCont {
    width: 240px;
    min-width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 0px 0px;
}

.labelTitle {
    margin: 0px 12px 0px 12px;
    font: var( --font-btn );
    color: var( --color-type-medium-emphasis );
}

#userItemCont {
    max-height: 280px;
    overflow-y: scroll;
    border-bottom: var( --border-light );
}

#bottomCont {
    margin-top: var( --space-small );
    display: flex;
}

#gap {
    width: 10px;
}

#errorMessage {
    margin-top: 12px;
    font: var( --font-body-small );
    color: var( --color-error-medium );
}