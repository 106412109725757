.mainCont {
    position: absolute;
    top: 0; right:0; bottom: 0; left: 0;
    display: flex;
    flex-direction: column;
    padding: var( --space-very-large );
    padding-top: 72px;
    direction: ltr;
    overflow-x: auto;
}

.topBar {
    position: absolute;
    top: 0px; right:0px; left: 0px;
    padding: var( --space-small );
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemTitle {
    margin-inline-start: var( --space-small );
    font: var( --font-body-small-medium );
    color: var( --color-type-medium-emphasis );
}

.conditionsCont {
    flex: 0;
    margin-bottom: var( --space-medium );
    height: fit-content;
}

.actionsCont {
    flex: 1;
}

.header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}


.headline {
    font: var( --font-headline-h2-bold );
    margin: 0;
    padding: 2px 12px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 16px;
    border: 2px solid transparent;
    color: var( --color-type-high-emphasis );
    transition-property: background-color, color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    user-select: none;
}

.headline:hover {
    border: 2px solid var(--color-type-high-emphasis );
}

.slash {
    height: 40px;
    width: 3px;
    min-width: 3px;
    background-color: var( --color-type-medium-emphasis );
    transform: rotate(30deg);
    margin: 0 16px;
}