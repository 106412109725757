.basic {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 6px; */
    /* font-size: 16px;
    font-weight: 200; */
    cursor: pointer;
    background-color: transparent;
    border-radius: var( --corner-radius-default );
    outline: none;
    transition: 0.25s ease-out;
    
}

.basic:focus {
    border-color: black;
}

.label {
    pointer-events: none;
    user-select: none;
    font: var( --font-btn );
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.05em;
}

.gap {
    width: 8px;
    max-width: 8px;
}

