.rootCont {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 20;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-light);
    padding: 0px var(--space-small);
    background-color: var(--color-background-light);
}

.logoSection {
    display: flex;
    align-items: center;
    min-width: 300px;
    font: var(--font-headline-h2);
    font-weight: 400;
}

.barEndCont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
}

.topicLink {
    color: var(--color-type-high-emphasis);
    margin-inline-start: 16px;
    margin-inline-end: 8px;
}

.topicLink:hover {
    color: var(--color-primary-medium);
}

.resourceTypeLabel {
    font: var(--font-body-extra-small);
    background-color: #d3d5e0;
    border-radius: 4px;
    margin-inline-start: 16px;
    padding: 2px 4px;
    text-transform: uppercase;
    display: flex;
    gap: 2px;
}
