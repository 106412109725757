.app_root {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main_area {
    position: absolute;
    top: 43px; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    background-color: var( --color-background-light );
}