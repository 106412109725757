.mainCont {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;

    overflow-y: auto;
}

.noPagesCont {
    position: absolute;
    top: 64px; right: 0; left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
}

.noPageText {
    font-size: 24px;
    margin: 0;
}

.pageItemsListCont {
    width: 100%;
    
    padding: 32px 0;
}

.tobBarCont {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);

    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}

.autoSaveLabel {
    color: var(--color-type-low-emphasis);
    margin: 0 8px;
}

.autoSaveIndicator {
    display: flex;
    align-items: center;
}

.langBtnCont {
    display: flex;
    align-items: center;
    color: var( --color-type-medium-emphasis );
}

.loaderCont {
    position: absolute;
    top: 64px; right: 0; left: 0; bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

