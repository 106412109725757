.mainInputsCont {
    display: flex;
}

.inputField {
    margin-inline-end: var(--space-small );
}

.gap {
    min-width: var(--space-small);
}