.modalContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    /* background-color: rgba(0,0,0,0.1);
    backdrop-filter: blur(6px) grayscale(50%); */
}

.modalPanel {
    background-color: var(--color-background-light);
    box-sizing: border-box;
    min-width: 200px;
    max-width: calc(100vw - 128px);
    min-height: 100px;
    max-height: calc(100vh - 128px);
    padding: var(--space-medium);
    border-radius: var(--corner-radius-large);
    overflow-y: auto;
    pointer-events: all;

    /* filter: var(--shadow-medium); */
    box-shadow: var(--box-shadow-medium);
}
