.mainPanel {
    position: relative;
    width: calc(100vw - 64px);
    height: calc(100vw - 64px);
    overflow: hidden;
}

/* TopBar */
.topBar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 48px;
    border-bottom: var(--border-light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px var(--space-small);
    box-sizing: border-box;
}

.libraryTitle {
    margin-inline-end: auto;
}

.multipleEditBar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: var(--color-background-light);
    box-shadow: var(--box-shadow-medium);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px var(--space-small);
    box-sizing: border-box;
}

.selectedFilesCounter {
    color: var(--color-type-medium-emphasis);
    border-inline-end: var(--border-main);
    padding-inline-end: var(--space-medium);
}

/* Thumbnails */
.thumbnailsRoot {
    position: absolute;
    z-index: 0;
    top: 48px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    padding: var(--space-medium);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.noFilesCont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: var(--space-very-large);
    font: var(--font-headline-h3);
    color: var(--color-type-medium-emphasis);
}

.thumbnailCont {
    position: relative;
    width: 200px;
    height: 210px;
    margin-inline-end: var(--space-small);
    margin-bottom: var(--space-small);
    border: var(--border-light);
    border-radius: var(--corner-radius-default);
    overflow: hidden;

    cursor: pointer;
}

.thumbnail {
    display: block;
    width: 100%;
    height: 150px;
    object-fit: scale-down;
}

.thumbnailMeta {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.fileName {
    display: block;
    margin: 8px 8px 0;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.fileDate {
    display: block;
    line-height: normal;
    color: var(--color-type-low-emphasis);
    overflow: visible;
    margin: 0 8px 4px;
}

.thumbNailTopBar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 48px;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 100%
    );
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 8px;
    box-sizing: border-box;
    gap: 8px;
}

.dropFilesIndicator {
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: #ffffffea;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Add media file */
.addCont {
    position: absolute;
    bottom: var(--space-medium);
    right: var(--space-medium);
    left: var(--space-medium);
    display: flex;
    justify-content: flex-end;
}

.addOptionItem {
    display: flex;
    align-items: center;
}

/* Accessibility */
.accessibilityPanelTopCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
