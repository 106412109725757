.container {
    display: flex;
    align-items: center;
}

.box {
    width: 24px;
    height: 24px;
    border-radius: var(--corner-radius-default);
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.label {
    font: var(--font-body-small);
    color: var(--color-type-high-emphasis);
    margin: 0 8px;
    cursor: pointer;
}
