.titleCont {
    display: flex;
    align-items: center;
    margin: 8px 0 16px 0;
}

.sectionsLabel {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin: 0 8px;
}

.weightsDialogTopCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 var( --space-small ) 0;

}

.weightsDialogTitle {
    font: var( --font-body );
    margin: 0
}

.pageWeightItemCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var( --space-small );
    border-bottom: var( --border-light );
}

.gap {
    width: 12px;
}

.weightInput {
    width: 100px;
}