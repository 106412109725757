.mainCont {
    position: relative;
}

.fieldsCont {
    position: relative;
    display: flex;
    align-items: stretch;
}

.title {
    font: var( --font-body-small );
    color: var( --color-type-low-emphasis );
    margin-bottom: var( --space-medium );
    text-transform: uppercase;
}

.fields {
    margin-bottom: 8px;
}

.sharedSettingsCont {
    width: 300px;
}

.typeSpecificCont {
    width: 300px;
}

.separator {
    border-right: var(--border-light);
    margin: 0 var(--space-large);
}

.gap{ 
    width: 6px;
}

.bottomCont {

    display: flex;
    justify-content: flex-end;
    margin-top: var( --space-medium );

}

.activityBlock {
    position: absolute;
    top: 0; right:0; bottom:0; left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var( --color-background-mild );
    opacity: 0.8;
}


.projectNameLabel {
    font: var( --font-body-medium );
}

.projectSourceNameLabel {
    color: var( --color-type-low-emphasis );
}