@charset "UTF-8";

@font-face {
    font-family: 'Ploni';
    font-weight: 400;
    src: url('./assets/fonts/ploni-regular-aaa.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
    font-family: 'Ploni';
    font-weight: 500;
    src: url('./assets/fonts/ploni-medium-aaa.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
    font-family: 'Ploni';
    font-weight: 600;
    src: url('./assets/fonts/ploni-demibold-aaa.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
    font-family: 'Ploni';
    font-weight: 700;
    src: url('./assets/fonts/ploni-bold-aaa.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
    font-family: 'Plex-Mono';
    font-weight: 400;
    src: url('./assets/fonts/IBMPlexMono-Regular.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
    font-family: 'Plex-Mono';
    font-weight: 700;
    src: url('./assets/fonts/IBMPlexMono-Bold.woff2') format('woff2'); /* Super Modern Browsers */
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    /* Colors */
    --color-primary-extra-light: #f0f4ff;
    --color-primary-light: #d6e0ff;
    --color-primary-medium-l: #a1b9ff;
    --color-primary-medium: #4877ff;
    --color-primary-dark: #0e15b6;
    --color-secondary-extra-light: #fff6df;
    --color-secondary-light: #ffe49b;
    --color-secondary-medium: #ffcf46;
    --color-secondary-dark: #a18212;

    --color-onPrimary: #ffffff;
    --color-onSecondary: #000000;
    --color-onDark: #ffffff;

    --color-background-light: #ffffff;
    --color-background-mild: #f8f8fd;
    --color-background-medium: #c0c2cc;
    --color-background-dark: #808390;
    --color-background-extradark: #44464b;
    --color-background-xxdark: #2a2a3f;
    --color-surface: #ffffff;
    --color-warning: #ffa10b;
    --color-success-light: #e0f2f1;
    --color-success: #26a69a;
    --color-error-light: #fce4ec;
    --color-error-medium: #c4223a;
    --color-error-dark: #5b0213;
    --color-border-light: rgba(0, 0, 0, 0.12);
    --color-border-medium: rgba(0, 0, 0, 0.24);

    --color-border-hover: rgba(0, 0, 0, 0.1);
    --color-background-hover: rgba(0, 0, 0, 0.03);

    --color-type-high-emphasis: rgba(0, 0, 0, 1);
    --color-type-medium-emphasis: rgba(0, 0, 0, 0.7);
    --color-type-low-emphasis: rgba(0, 0, 0, 0.35);
    --color-type-disabled: rgba(0, 0, 0, 0.2);

    /* Spacing */
    --space-very-large: 48px;
    --space-large: 32px;
    --space-medium: 24px;
    --space-small: 16px;

    /* sizes */
    --size-header: 44px;

    /* Corner Radius */
    --corner-radius-default: 5px;
    --corner-radius-large: 8px;
    --corner-radius-very-large: 16px;

    /* borders */
    --border-light: 1px solid var(--color-border-light);
    --border-main: 1px solid var(--color-border-medium);

    /* fonts */
    --font-btn: 600 16px / normal 'Ploni', sans-serif;
    --font-body: 400 18px/1.3 'Ploni', sans-serif;
    --font-body-medium: 600 18px / normal 'Ploni', sans-serif;
    --font-body-bold: 700 18px / normal 'Ploni', sans-serif;
    --font-body-extra-small: 500 12px/1.2 'Ploni', sans-serif;
    --font-body-small: 500 16px/1.3 'Ploni', sans-serif;
    --font-body-small-medium: 600 16px / normal 'Ploni', sans-serif;
    --font-body-small-bold: 700 16px / normal 'Ploni', sans-serif;
    --font-headline-h1: 500 32px/1.5 'Ploni', sans-serif;
    --font-headline-h1-bold: 700 32px/1.5 'Ploni', sans-serif;
    --font-headline-h2: 600 24px/1.4 'Ploni', sans-serif;
    --font-headline-h2-bold: 700 24px / normal 'Ploni', sans-serif;
    --font-headline-h3: 600 22px / normal 'Ploni', sans-serif;
    --font-headline-h3-bold: 700 22px / normal 'Ploni', sans-serif;
    --font-number: 500 28px / normal 'Ploni', sans-serif;
    --font-code: 400 16px / normal 'Plex-Mono', 'monospace';
    --font-code-bold: 700 16px / normal 'Plex-Mono', 'monospace';

    /* Shadows */
    --shadow-extra-low: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    --shadow-low: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    --shadow-medium: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.15));
    --shadow-high: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.15));

    --box-shadow-low: 0 2px 6px rgba(0, 0, 0, 0.15);
    --box-shadow-medium: 0 8px 16px rgba(0, 0, 0, 0.15);
}

::selection {
    background: var(--color-primary-light); /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: var(--color-primary-light); /* Gecko Browsers */
}

body {
    margin: 0;
    font-family: 'Ploni', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(
        255,
        255,
        255,
        0
    ); /* removes Android tap highlight */
    overflow: hidden;
}

h2 {
    font: var(--font-headline-h2);
    margin: 0px;
}

h3 {
    font: var(--font-headline-h3);
    margin: 0px;
}

p {
    font: var(--font-body-small);
    margin: 0px;
}

a {
    color: var(--color-primary-medium);
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    border-bottom: 1px solid var(--color-primary-medium);
}

b {
    font-family: 'Ploni';
}
