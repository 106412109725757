.mainCont {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0;
    border-radius: 100%;
    pointer-events: none;
    opacity: 0.9;
}

.circle {
    position: absolute;
    top: 0; right: 0; bottom: 0; left:0;
    border: 6px solid black;
    border-radius: 50%;
    box-sizing: border-box;
}

.midLine {
    position: absolute;
    height: 6px;
    width: 100%;
    top: calc( 50% - 3px );
    background-color: black;
}

.fill {
    position: absolute;
    top: 50%; right: 0; bottom: 0; left: 0;
    background-color: black;
    transform-origin: top center;
    border-radius: 0 0 64px 64px;
}