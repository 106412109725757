.itemCont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var( --space-small );
    height: 32px;
    padding: 0 6px;
}

.itemCont:hover {
    background-color: rgba(0,0,0,0.05);
}

.gap {
    width: 6px;
    max-width: 6px;
}

.typeSelectionCont {
    max-width: 24px;
}

.midCont {
    flex: 2;
    direction: ltr;
    display: flex;
    align-items: center;
    margin: 0 12px;
}

.varName{

    min-width: calc( 40% - 12px );

    font: var( --font-code-bold );
    text-align: end;
}

.eqLabel { 
    min-width: 24px;
    font: var( --font-code );
    text-align: center;
}

.valueInput{
    min-width: calc( 60% - 12px );
}

.booleanCont {
    min-width: calc( 60% - 12px );
}

.newVarTip {
    position: absolute;
    left: 0; right: 0; bottom: -32px;
    height: 24px;
    color: var( --color-primary-medium );
}