.relatedFieldsIndicator {
    position: absolute;
    top: 42px; bottom: 16px; 
    left: -12px; right: -12px;
    width: 12px;
    /* border-radius: var(--corner-radius-default); */
    border: var( --border-main );
}

.listFieldContainer {
    margin-top: var( --space-large );
    margin-bottom: var( --space-very-large );
}

.fieldItemCont {
    position: relative;
    margin: 8px -24px 16px -24px;
    padding: 0 24px;
}

.separationLine {
    border-bottom: var( --border-light );
    margin: var( --space-medium ) 0;
}

.frame {
    pointer-events: none;

    position: absolute;
    top: -18px; 
    right: -32px; 
    bottom: -24px; 
    left: -32px;

    border: var( --border-main );
    border-radius: var( --corner-radius-default );
}

.spreadedKeysCont {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.spreadedKeysLabel {
    padding: 8px 16px;
    box-sizing: border-box;
    min-width: 50%;
    width: 50%;
    border: var( --border-main );
    border-radius: var( --corner-radius-default );
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spreadedKeysLabel:active {
    z-index: 2;
    min-width: fit-content;
    background-color: var( --color-background-mild );
}

.subItemIndicator{ 
    position: absolute;
    width: 12px;
    height: 42px;
    bottom: 18px;
    left: -12px; right: -12px;
    border: var( --border-main );
    border-top: none;
}

.colorInput {
    background-color: white;
    border-radius: var( --corner-radius-default );
    align-self: stretch;
    width: 100%;
}

.dropdownKeyLabel {
    font: var( --font-body-medium );
}

.dropdownTypeLabel {
    color: var( --color-type-low-emphasis );
}

.dropdownTitleLabel {
    color: var( --color-type-medium-emphasis );
    font: var( --font-body-small );
}