.pickerCont {
    display: flex;
    width: 256px;
    flex-wrap: wrap;
    max-height: 40vh;
    overflow-y: auto;
}

.emojiBtn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    width: 32px;
    height: 32px;

    font-size: 24px;
    cursor: pointer;
}

.emojiBtn:hover {
    background-color: var(--color-primary-light);
}