.actionItemsListCont {
    margin-top: var(--space-medium);
}

.actionItemCont {
    position: relative;
    box-sizing: border-box;
    min-height: 48px;
    width: 100%;
    min-width: fit-content;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 16px;
    margin-bottom: 8px;

    background-color: rgba( 255,255,255,0.7 );
    border-radius: var( --corner-radius-large );
}

.dragHandle {
    --border: 1px solid var( --color-type-medium-emphasis );

    width: 18px; height: 8px;
    border-top: var( --border );
    border-bottom: var( --border ); 
}

.itemNumberLabel {
    font: var( --font-headline-h2-bold );
    padding: 0 16px
}

.actionItemContent {
    /* direction: ltr; */
    flex: 1;
    display: flex;
    align-items: center;
}

.assignmentBtnCont {

    margin-inline-start: 2px;
    margin-inline-end: 2px;
}

.assignmentIcon {
    direction: ltr;
    display: inline-block;
    font: var( --font-code-bold );
    margin-inline-end: 8px;
    width: 32px;
    text-align: center;
}

.moduleCont{
    width: 100%;
    margin-inline-end: 8px;
}