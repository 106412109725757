.gutter {
    z-index: 2;
}

.gutter:hover {
    background-color: rgba( 0,0,0,0.05 );
}

.gutter.gutter-horizontal {
    cursor: ew-resize;
    border: var( --border-light );
    border-width: 0 1px;
    /* margin: 0 -5px; */
}