input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 24px;
	height: 24px;
	padding: 0;
	cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
	border-radius: var(--corner-radius-default);
	padding: 0;
	margin: 0;
}

.container {
	position: relative;
	display: inline-block;
}

.label {
    display: block;
    font: var( --font-body-small );
    margin-bottom: 4px;
    white-space: nowrap;
}

.popperCont {
	max-height: 300px;
	max-width: 200px;
	overflow-y: auto;  
	box-sizing: border-box;
	padding: 0 var( --space-small );
}

.btnCont {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.currentColor {

	--size: 18px;

	position: absolute;
	top: calc( 50% - 0.5 * var( --size ) );
	right: calc( var(--size) / 2 ); left: calc( var(--size) / 2 );

	width: var( --size );
	height: var( --size );
	border-radius: calc( var(--size) * 0.5 );

}

.presetesCont {
	display: flex;
	flex-wrap: wrap;
}

.presetColor {
	--size: 24px;
	width: var( --size );
	height: var( --size );
	border-radius: var( --corner-radius-default );
	margin: 2px;
	cursor: pointer;
}

.popperCuerrentColorLabel {
	color: var( --color-type-low-emphasis );
	margin-bottom: 8px;
}

.customColorPickerCont {
	display: flex;
	align-items: center;
	border: var( --border-light );
	border-radius: var(--corner-radius-default);
	overflow: hidden;
	filter: var( --shadow-low );
	margin: 2px;

	flex-grow: 2;
	cursor: pointer;
}

.customColorPickerCont:hover {
	background-color: var(--color-background-hover );
}

.customColorLabel {
	margin: 0 8px;
	pointer-events: none;
}